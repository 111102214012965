import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const TriangleBlogPost = styled(Box)`
  z-index: 2;
  height: 140px;
  bottom: -140px;
  transform: skewY(-4.2deg);
  transform-origin: top left;
  background: ${themeGet("colors.white.100")};

  @media screen and (max-width: ${themeGet("breakpoints.lg")}) {
    display: none;
  }

  @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
    // height: 600px;
    // bottom: -50px;
    // background: linear-gradient(0deg, rgba(46, 46, 56, 1) 0%, rgba(46, 46, 56, 1) 30%, rgba(91, 91, 105, 0) 100%);
  }

  @media screen and (min-width: 1920px) {
    height: 10vw;
    bottom: -10vw;
    transform: skewY(-3.2deg);
  }
`;

TriangleBlogPost.defaultProps = {
  as: "span",
  position: "absolute",
  left: "0",
  width: "100%",
};

export default TriangleBlogPost;
