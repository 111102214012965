import React from "react";
import { HeadingWithLineCenter } from "../../style/Heading/HeadingWithLineCenter";
import { CardImage } from "../Card/CardImage";
import { BackgroundImage } from "../../style/BackgroundImage/BackgroundImage";
import { Flex } from "../../style/Grid";

const CardEmpty = ({ link, content, ...props }) => {
  return (
    <Flex
      display="flex"
      width={["91%", null, "70%", "60%", null]}
      border="1px solid #c4c4cd"
      margin="0 auto"
      justifyContent="center"
      alignItems="center"
      py={["4", null, "5", null, null]}
      px={["10%", null, null, null, null]}
      mt="50px"
      textAlign="center"
    >
      <HeadingWithLineCenter
        pb={["3", "3", "3", "3", "3"]}
        as="h2"
        fontSize={["18px", null, "24px", "24px", "24px"]}
        fontWeight="300"
        color={["18px", null, null, null, null]}
        lineHeight={["3", "3", "3", "3", "3"]}
      >
        V&nbsp;tuhle chvíli pro tebe připravujeme nové zajímavé akce,
        na&nbsp;kterých se&nbsp;spolu můžeme potkat.
      </HeadingWithLineCenter>
    </Flex>
  );
};

export default CardEmpty;
