import React from "react";
import { Dl, Dt, Dd, DlBorder } from "./PrimitivesLandscape";
import { DateHelper } from "../DateHelper";

export const ContentDl = ({ event }) => (
  <>
    <div>
      <Dt>Kdy</Dt>
      <Dd>
        <DateHelper content={event} />
      </Dd>
    </div>
    <div>
      <Dt>Kde</Dt>
      <Dd>{event.place}</Dd>
    </div>
  </>
);

const EventContent = ({ event, ...props }) => (
  <>
    {props.eventPost && (
      <DlBorder>
        <ContentDl event={event} />
      </DlBorder>
    )}
    {!props.eventPost && (
      <Dl>
        <ContentDl event={event} />
      </Dl>
    )}
  </>
);

export default EventContent;
