import React from "react";
import RecruiterPhoneNumber from "../../components/RecruiterPhoneNumber";
import { Heading } from "../../style/Heading";
import { Flex } from "../../style/Grid";
import { List } from "../../style/List";
import { Image } from "../../style/Image";
import Container from "../../style/Grid/Container";
import getStyledLink from "../../style/Link/getStyledLink";
const Link = getStyledLink("a");

const ContactFloor = ({ recruiters }) => (
  <Flex
    as="section"
    minHeight="600px"
    bg="EYOffBlack.default"
    justifyContent="center"
  >
    <Container
      width={["100%", null, null, null, null]}
      flexDirection="column"
      mt={["30px", null, null, "30px", null]}
      px={["30px", null, null, recruiters?.length > 6 ? "50px" : "100px", null]}
      py={["60px", null, null, "120px", null]}
    >
      <Heading
        as="h2"
        color="EYYellow.default"
        fontSize={[4, null, null, 5, 6]}
        fontWeight="light"
        flexBasis="100%"
        mb="5"
      >
        Chceš se nás na něco zeptat?
      </Heading>
      <Flex
        flexDirection={["column", null, "row", null, "row"]}
        flexWrap={["wrap", null, null, null, null]}
      >
        {recruiters.map((recruiter) => (
          <Flex
            width={["100%", "100%", "50%", null, recruiters?.length > 6 ? "25%" : "33%"]}
            alignItems="flex-start"
            justifyContent="flex-start"
            pl={["0", null, null, null, null]}
          >
            {recruiter?.image?.childImageSharp && (
              <Image
                image={recruiter.image}
                width={["88px", null, recruiters?.length > 6 ? "88px" : "120px", null, null]}
                height={["88px", null, recruiters?.length > 6 ? "88px" : "120px", null, null]}
                ml={["0", null, null, null, null]}
                mr={recruiters?.length > 6 ? "15px" : "25px"}
                borderRadius="50%"
                style={{ flexShrink: 0 }}
              />
            )}
            <Flex flexDirection="column">
              <Heading
                as="h3"
                fontSize="3"
                color="white.100"
                fontWeight="normal"
                lineHeight="2"
                mb="24px"
              >
                {recruiter.name}
              </Heading>

              <RecruiterPhoneNumber
                phoneNumber={`${recruiter.phone}`}
                color="white.100"
                fontWeight="light"
                lineHeight="2"
                mb="2px"
                fontSize={["1", "2", null, null, null]}
                white={true}
              />

              <Link
                href={`mailto:${recruiter.email}`}
                color="white.100"
                fontWeight="light"
                lineHeight="4"
                mb="2px"
                fontSize={["1", "2", null, null, null]}
                white={true}
                mt="4px"
              >
                {recruiter.email}
              </Link>
              <List color="white.100" fontWeight="300">
                {recruiter.departments.map((name) => (
                  <li>{name}</li>
                ))}
              </List>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Container>
  </Flex>
);

export default ContactFloor;
