import React, { useState, useRef, useEffect, useCallback } from "react";
import Container from "../../style/Grid/Container";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Flex } from "../../style/Grid";
import { Tab } from "../../style/Tab";
import { AmbassadorCard } from "../../style/AmbassadorCard";
import { ButtonWithIcon } from "../../style/ButtonWithIcon";
//import { AmbassadorsMap } from "../../style/AmbassadorsMap";
import { AmbassadorButtonsWrapper } from "../../style/AmbassadorButtonsWrapper";

const CZECH = "Česko";
const FOREIGN_COUNTRIES = "Zahraničí";

const AmbassadorsFloor = ({ ambasadors, places, blogPosts }) => {
  const [activeTab, setActiveTab] = useState(CZECH);
  const [disableLeftButton, setDisableLeftButton] = useState(false);
  const [disableRightButton, setDisableRightButton] = useState(false);
  const [ambasadorsData, setAmbasadorsData] = useState([]);
  const [placesData, setPlacesData] = useState([]);

  const sliderRef = useRef(null);
  const refs = useRef([]);

  useEffect(() => {
    const _ambasadorsData = ambasadors
      .filter((e) => {
        if (activeTab === CZECH) {
          return !e.node.frontmatter.isForeign;
        } else {
          return e.node.frontmatter.isForeign;
        }
      })
      .map((e) => {
        const {
          id,
          frontmatter: {
            name,
            avatarImage,
            place,
            department,
            whatsappLink,
            linkedinLink,
            email,
            phoneNumber,
            articleLink,
          },
        } = e.node;

        const cardPlace = places?.find((i) => i.node.frontmatter.name === place)
          ?.node?.frontmatter?.nameInCard;

        const articleSlug = blogPosts?.find(
          (i) => i.frontmatter.title === articleLink
        )?.fields?.slug;

        return {
          id: id,
          name: name,
          avatarImage: avatarImage,
          place: place,
          cardPlace: cardPlace,
          department: department,
          whatsappLink: whatsappLink,
          linkedinLink: linkedinLink,
          email: email,
          phoneNumber: phoneNumber,
          articleLink: articleSlug,
        };
      });

    const _placesData = places
      .filter((e) => {
        if (activeTab === CZECH) {
          return !e.node.frontmatter.isForeign;
        } else {
          return e.node.frontmatter.isForeign;
        }
      })
      .map((e) => {
        const {
          id,
          frontmatter: { name, longitude, latitude },
        } = e.node;

        return {
          id: id,
          name: name,
          ambasadors: _ambasadorsData.filter((i) => i.place === name),
          location: {
            latitude: latitude,
            longitude: longitude,
          },
        };
      });

    setAmbasadorsData(_ambasadorsData);
    setPlacesData(_placesData);
  }, [activeTab]);

  const getActiveCard = useCallback(() => {
    if (sliderRef?.current) {
      const sliderScrollLeft = sliderRef.current.scrollLeft;
      const sliderPaddingLeft = refs.current[0].offsetLeft || 0;

      let _activeCard = 0;

      for (let i = 0; i < refs.current.length; i++) {
        const { id, clientWidth, offsetLeft } = refs.current[i];
        if (clientWidth + offsetLeft - sliderPaddingLeft > sliderScrollLeft) {
          _activeCard = Number(id);
          break;
        }
      }

      return _activeCard;
    } else return 0;
  }, [sliderRef, sliderRef?.current]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollWidth, offsetWidth, scrollLeft } = sliderRef.current;

      setDisableLeftButton(scrollLeft == 0);
      setDisableRightButton(scrollWidth == offsetWidth + scrollLeft);
    };

    if (sliderRef?.current) {
      handleScroll();
      sliderRef.current.addEventListener("scroll", handleScroll, false);
    }

    return () => {
      if (sliderRef?.current) {
        sliderRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [sliderRef, ambasadorsData]);

  const moveSlider = useCallback((value) => {
    sliderRef.current.scrollTo({
      left: value,
      behavior: "smooth",
    });
  }, []);

  const onLeftArrowClick = useCallback(() => {
    if (!refs?.current[0]) return null;

    const tab = getActiveCard();

    if (tab === 0) {
      moveSlider(0);
    } else {
      const { offsetLeft } = refs.current[tab - 1];
      moveSlider(offsetLeft - refs.current[0].offsetLeft);
    }
  }, [refs?.current]);

  const onRightArrowClick = useCallback(() => {
    if (!refs?.current[0]) return null;

    const tab = getActiveCard();

    const { offsetLeft } = refs.current[tab + 1];
    moveSlider(offsetLeft - refs.current[0].offsetLeft);
  }, [refs?.current]);

  return (
    <Container width="100%" flexDirection="column" px="0" py="0">
      <Container
        width={["100%", null, null, null, null]}
        flexDirection="column"
        px={[null, null, null, null, null]}
        pt={["40px", null, null, null, "64px"]}
        pb={["64px", null, null, null, "80px"]}
        bg="EYYellow.default"
      >
        <Container
          width={["100%", null, null, null, null]}
          flexDirection="column"
          px={["32px", null, null, null, null]}
          py={[null, null, null, null, null]}
          bg={null}
        >
          <Heading
            as="h2"
            color="EYBlack.default"
            fontSize={["24px", null, null, null, "40px"]}
            lineHeight={["28px", null, null, null, "64px"]}
            fontWeight="400"
            mb="16px"
            textAlign={["left", null, null, null, "center"]}
          >
            Naši ambasadoři
          </Heading>
          <Text
            width={["100%", null, null, null, null]}
            color="EYBlack.default"
            fontWeight="light"
            fontSize={[2, null, null, 2, null]}
            lineHeight="24px"
            maxWidth={[null, null, null, null, "688px"]}
            textAlign={["left", null, null, null, "center"]}
            mx={[null, null, null, null, "auto"]}
          >
            Zajímá tě, jak to u nás doopravdy chodí? Kromě našich recruiterek se
            můžeš na cokoliv zeptat i EY ambasadorů nejen z českých, ale i
            zahraničních univerzit. Většina ambasadorů si práci u nás navíc
            vyzkoušela v rámci internshipu, pomůžou ti tak lépe nahlédnout pod
            pokličku práce konzultantů.
          </Text>
        </Container>
        <Container
          width={["100%", null, null, null, null]}
          flexDirection="column"
          px={["32px", null, null, null, "114px"]}
          py={[null, null, null, null, null]}
          bg={null}
          mt={["32px", null, null, null, "64px"]}
        >
          <Heading
            as="h3"
            fontSize={["18px", null, null, null, "24px"]}
            lineHeight={["24px", null, null, null, "32px"]}
            color="EYBlack.default"
            fontWeight="700"
          >
            Kde všude najdeš naše ambasadory?
          </Heading>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" mt={["16px", null, null, null, "32px"]}>
              <Tab
                onClick={() => setActiveTab(CZECH)}
                active={activeTab === CZECH}
              >
                {CZECH}
              </Tab>
              <Tab
                onClick={() => setActiveTab(FOREIGN_COUNTRIES)}
                active={activeTab === FOREIGN_COUNTRIES}
              >
                {FOREIGN_COUNTRIES}
              </Tab>
            </Flex>
            <AmbassadorButtonsWrapper>
              <ButtonWithIcon
                name="arrowLeft"
                onClick={onLeftArrowClick}
                disable={disableLeftButton}
              />
              <ButtonWithIcon
                name="arrowRight"
                ml="16px"
                onClick={onRightArrowClick}
                disable={disableRightButton}
              />
            </AmbassadorButtonsWrapper>
          </Flex>
        </Container>
        <Flex
          className="hidScrollbar"
          alignItems="strech"
          mt={["16px", null, null, null, "24px"]}
          overflow="auto"
          px={["32px", null, null, null, "114px"]}
          ref={sliderRef}
        >
          {ambasadorsData.map((e, index) => (
            <Flex
              id={index}
              key={e.id}
              width={["70%", null, "340px", null, "420px"]}
              minWidth={["290px", null, "340px", null, "420px"]}
              pr={
                index < ambasadorsData.length - 1
                  ? ["18px", null, null, null, "26px"]
                  : "0"
              }
              ref={(element) => {
                refs.current[index] = element;
              }}
            >
              <AmbassadorCard {...e} width="100%" />
            </Flex>
          ))}
        </Flex>
      </Container>
      {/*placesData.length > 0 && (
        <AmbassadorsMap data={placesData} activeTab={activeTab} />
      ) */}
    </Container>
  );
};

export default AmbassadorsFloor;

/*

media

*/
