import styled from "styled-components";
import { HeadingWithLine } from "./HeadingWithLine";

const HeadingWithLineCenter = styled(HeadingWithLine)`
  position: relative;
  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
`;
export { HeadingWithLineCenter };
