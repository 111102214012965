import styled, { css } from "styled-components";
import TabComponent from "../../components/Tab";
import { space, layout, border, position, color } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Tab = styled(TabComponent)`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  color: ${themeGet("colors.EYOffBlack.default")};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 24px 16px 24px;
  ${space}
  ${layout}
  ${border}
  ${position}
  ${color}

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #2e2e38;
    left: 0;
    bottom: 0;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: 700;

      &::after {
        height: 3px;
        bottom: -1px;
      }
  `}

  @media screen and (min-width: ${themeGet("breakpoints.xl")}) {
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px 8px 24px;
  }
`;

Tab.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { Tab };
