import styled, { css } from "styled-components";
import ButtonWithIconComponent from "../../components/ButtonWithIcon";
import { space, layout, border, position, color } from "styled-system";
import propTypes from "@styled-system/prop-types";

const ButtonWithIcon = styled(ButtonWithIconComponent)`
  cursor: pointer;
  transition: background-color 0.2s, opacity 0.2s;
  ${space}
  ${layout}
  ${border}
  ${position}
  ${color}

  &:hover {
    background-color: #2E2E38;

    .icon {
        color: #FFE600;
    }
  }

  .icon {
    color: #2E2E38;
    transition: color 0.2s;
  }

  &.disable {
    opacity: 0.32;
    cursor: default;

    &:hover {
      background-color: transparent;

      .icon {
        color: #2E2E38;
      }
    }
  }
`;

ButtonWithIcon.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { ButtonWithIcon };
