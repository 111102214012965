import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { Flex } from "../Grid";

const AmbassadorButtonsWrapper = styled(Flex)`
    align-items: center;
    display: none;

    @media (pointer: fine) {
        display: flex;
    }

    @media screen and (min-width: 1250px) {
        display: flex;
    }
`;

AmbassadorButtonsWrapper.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { AmbassadorButtonsWrapper };
