import React from "react";
import moment from "moment";

export const DateHelper = ({ content: { dateFrom, dateTo } }) => {
  require("moment/locale/cs");


  dateFrom = moment(dateFrom, ["YYYY-MM-DD, HH:mm Z", "DD. MM. YYYY HH:mm"]);
  dateTo = moment(dateTo, "YYYY-MM-DD, HH:mm Z");

  const timeFrom = dateFrom.format("HH:mm");
  const timeTo = dateTo.format("HH:mm");

  const sameDay = dateFrom.format("DD") === dateTo.format("DD");
  const sameMonth = dateFrom.format("MM") === dateTo.format("MM");

  const dateString = `${dateFrom.format("D")}. ${
    sameMonth ? "" : `${dateFrom.format("MMMM")}`
  }${sameDay ? "" : ` – ${dateTo.format("D")}.`} ${dateTo.format(
    "MMMM"
  )} ${dateTo.format("YYYY")}`;

  return (
    <>
      {dateString}
      <br />
      {timeFrom} – {timeTo}
    </>
  );
};
