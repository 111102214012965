import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import moment from "moment";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import Button from "../components/Button";
import Header from "../components/Header";
import Card from "../components/Card/Card";
import EventContent from "../components/Card/EventContent";
import CardEmpty from "../components/Card/CardEmpty";
import { Heading } from "../style/Heading";
import { Flex } from "../style/Grid";
import TriangleBlogPost from "../style/Header/triangleBlogPost";
import { Text } from "../style/Text";
import { URL } from "../constants";
import usePageView from "../utils/usePageView";
import ContactFloor from "../components/ContactFloor";
import AmbassadorsFloor from "../components/AmbassadorsFloor";

const Event = ({ data, location }) => {
  usePageView(location.pathname, "Potkej se s námi", "");

  const contactFloorData = [
    // {
    //   image: data.contactPersonImage7,
    //   name: "Sandra Jirásková",
    //   phone: "+420 735 729 285",
    //   email: "sandra.jiraskova@cz.ey.com",
    //   departments: ["Head of Recruitment"],
    // },
    {
      image: data.contactPersonImage1,
      name: "Tereza Pospíšilová",
      phone: "+420 703 891 368",
      email: "tereza.pospisilova1@cz.ey.com",
      departments: ["Audit (Praha)", "Forenzní služby", "FAAS & CCaSS"],
    },
    {
      image: data.contactPersonImage5,
      name: "Alice Baculáková",
      phone: "+420 730 191 753",
      email: "alice.baculakova@cz.ey.com",
      departments: ["Audit (Brno, Ostrava)", "Centrum auditních služeb"],
    },
    {
      image: data.contactPersonImage4,
      name: "Klára Fittnerová",
      phone: "+420 730 191 996",
      email: "klara.fittnerova@cz.ey.com",
      departments: ["Daně", "Právo", "Interní služby"],
    },
    // {
    //   image: data.contactPersonImage4,
    //   name: "Petra Černohorská",
    //   phone: "+420 731 627 159",
    //   email: "petra.cernohorska@cz.ey.com",
    //   departments: ["Daně", "Právo"],
    // },
    {
      image: data.contactPersonImage2,
      name: "Tereza Koštůrová",
      phone: "+420 704 865 153",
      email: "tereza.kosturova@cz.ey.com",
      departments: ["Business Consulting"],
    },
    {
      image: data.contactPersonImage8,
      name: "Lenka Tardíková",
      phone: "+420 731 642 798",
      email: "lenka.tardikova@cz.ey.com",
      departments: ["Technology Consulting"],
    },
    {
      image: data.contactPersonImage3,
      name: "Karolína Alexandrová",
      phone: "+420 730 191 790",
      email: "karolina.alexandrova@cz.ey.com",
      departments: ["Strategie a transakce", "EY-Parthenon"],
    },
    // {
    //   image: data.contactPersonImage6,
    //   name: "Petra Koubová",
    //   phone: "+420 731 627 158",
    //   email: "petra.koubova@cz.ey.com",
    //   departments: ["Interní služby"],
    // }
  ];

  //    const siteTitle = data.site.siteMetadata.title
  const events = data.allMdx.edges
    .filter(
      ({
        node: {
          frontmatter: { dateFrom, dateTo },
        },
      }) => {
        return moment(dateTo, "YYYY-MM-DD, HH:mm Z").isAfter();
      }
    )
    .sort((a, b) =>
      moment(a.node.frontmatter.dateFrom, "YYYY-MM-DD, HH:mm Z").diff(
        moment(b.node.frontmatter.dateFrom, "YYYY-MM-DD, HH:mm Z")
      )
    );

  const [eventLimit, setEventLimit] = useState(6);
  const [nextButtonShow, setShowNextButton] = useState(true);

  const handleEventLimit = () => setEventLimit(eventLimit + 3);

  let slicedEvents = events.slice(0, eventLimit);

  useEffect(() => {
    if (slicedEvents.length === events.length) {
      setShowNextButton(false);
    }
  }, [eventLimit]);

  return (
    <Layout location={location} title="event">
      <SEO
        title={"Potkej se s námi"}
        description={
          "Nejlepší jsme naživo. Přijď se podívat, jak měníme svět k lepšímu. Přihlas se na některou z našich akcí nebo si s námi rovnou domluv schůzku."
        }
      />
      <Header
        bgImage={data.headerImage}
        minHeight={["430px", null, "545px", null, null]}
        bottomComponents={<TriangleBlogPost />}
        containerJustifyContent="flex-end"
        shadowAfter={true}
      >
        <Heading
          as="h1"
          color="white.100"
          fontSize={[5, 6, 6, "48px", null]}
          fontWeight="300"
          lineHeight="1.3"
          maxWidth={["350px", "500px", "700px", null, null]}
          mb={["16px", null, null, "24px", null]}
        >
          Kde se můžeme vidět?
        </Heading>
        <Text
          color="white.100"
          fontWeight="300"
          fontSize={[2, null, null, 2, null]}
          lineHeight="24px"
          maxWidth={["350px", null, null, "400px", null]}
          mb={["45px", null, "84px", 6, null]}
        >
          Nejlepší jsme naživo. Přijď se podívat, jak měníme svět k lepšímu.
          Přihlas se na některou z našich akcí nebo si s námi rovnou domluv
          schůzku.
        </Text>
      </Header>
      <Flex
        flexDirection={["column", null, "row", null, null]}
        justifyContent="center"
        mb={["30px", null, null, "120px", null]}
      >
        {slicedEvents && slicedEvents.length !== 0 && (
          <Flex
            width={["100%", null, "91%", null, "1217px"]}
            flexDirection="column"
            alignItems="center"
          >
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              ml={["25px", null, 0, null, null]}
              mr={["25px", null, 0, null, null]}
              mt={["20px", null, "50px", null, null]}
              mb={["64px", null, null, null, null]}
              width={["91%", null, "100%", null, null]}
            >
              {slicedEvents.map(({ node: { fields, frontmatter } }) => {
                return (
                  <Card
                    key={fields.slug}
                    link={`${URL.events}${fields.slug}`}
                    content={frontmatter}
                    image={frontmatter.jobHeaderImageFile}
                    type="event"
                  >
                    <EventContent event={frontmatter} />
                  </Card>
                );
              })}
            </Flex>
            {nextButtonShow && (
              <Flex alignContent="center" justifyContent="center" width="100%">
                <Button
                  as="button"
                  variant="outlineBlack"
                  alignSelf="center"
                  onClick={handleEventLimit}
                  maxWidth="300px"
                  width={["264px", "280px", null, null, null]}
                  marginBottom={["64px", null, null, "120px", null]}
                  fontWeight="300"
                  fontSize="14px"
                  marginTop={"30px"}
                >
                  Co dalšího pořádáme
                </Button>
              </Flex>
            )}
          </Flex>
        )}
        {slicedEvents.length === 0 && <CardEmpty />}
      </Flex>
      <AmbassadorsFloor
        ambasadors={data.allAmbassador.edges}
        places={data.allPlaces.edges}
        blogPosts={data.allBlogPosts.nodes}
      />
      <ContactFloor recruiters={contactFloorData} />
    </Layout>
  );
};

export default Event;

export const pageQuery = graphql`
  query eventQuery {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "event-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            jobHeaderImageFile {
              childImageSharp {
                gatsbyImageData(width: 407, height: 333, quality: 90)
              }
            }
            dateFrom(formatString: "YYYY-MM-DD, HH:mm Z")
            dateTo(formatString: "YYYY-MM-DD, HH:mm Z")
            place
          }
        }
      }
    }
    allBlogPosts: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
    allAmbassador: allMdx(
      filter: { frontmatter: { templateKey: { eq: "ambassador" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            avatarImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            place
            isForeign
            department
            whatsappLink
            linkedinLink
            email
            phoneNumber
            articleLink
          }
        }
      }
    }
    allPlaces: allMdx(
      filter: { frontmatter: { templateKey: { eq: "places" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            nameInCard
            isForeign
            longitude
            latitude
          }
        }
      }
    }
    headerImage: file(
      relativePath: { eq: "header-backgrounds/potkej-se-s-nami-bg.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 90)
      }
    }
    contactPersonImage1: file(relativePath: { eq: "recruiters/TerezaPospisilova.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 100)
      }
    }
    contactPersonImage2: file(
      relativePath: { eq: "recruiters/Tereza-Majkova.jpeg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 90)
      }
    }
    contactPersonImage3: file(
      relativePath: { eq: "recruiters/KarolinaAlexandrova.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 100)
      }
    }
    contactPersonImage4: file(
      relativePath: { eq: "recruiters/KlaraFittnerova.jpeg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 90)
      }
    }
    contactPersonImage5: file(
      relativePath: { eq: "recruiters/AliceBaculakova.jpeg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 90)
      }
    }
    contactPersonImage6: file(relativePath: { eq: "recruiters/PetraKoubova.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 100)
      }
    }
    contactPersonImage7: file(relativePath: { eq: "recruiters/SandraJiraskova.png" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 100)
      }
    }
    contactPersonImage8: file(relativePath: { eq: "recruiters/LenkaTardikova.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 120, quality: 100)
      }
    }
  }
`;
