import React from "react";
import { Flex } from "../../style/Grid";
import Icon from "../Icons";
import classnames from "classnames";

const ButtonWithIconComponent = ({
  className,
  style,
  name,
  onClick,
  disable,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="40px"
      height="40px"
      border="1px solid #2E2E38"
      style={style}
      className={classnames(className, disable && "disable")}
      onClick={onClick}
    >
      <Icon name={name} width="24px" className="icon" />
    </Flex>
  );
};

export default ButtonWithIconComponent;
